import { HomepageQA } from 'shared/dataAttributes'
import { t } from 'localization'

const TP = 'homepage.clickableImagesContent'

export const clickableImagesContent = () => [
  {
    qaAttr: HomepageQA.ShopMen,
    href: '/men',
    src: 'https://cms-cdn.flightclub.com/eb23d10c185d-ca4a-ae11-d7ff-07f3c9db.png',
    label: t(`${TP}.shopMen`, 'Shop Men'),
  },
  {
    qaAttr: HomepageQA.ShopWomen,
    href: '/women',
    src: 'https://cms-cdn.flightclub.com/eb23d10c185d-ca4a-ae11-d7ff-02e69b9e.png',
    label: t(`${TP}.shopWomen`, 'Shop Women'),
  },
  {
    qaAttr: HomepageQA.ShopKids,
    href: '/youth',
    src: 'https://cms-cdn.flightclub.com/eb23d10c185d-ca4a-ae11-d7ff-05c496ef.png',
    label: t(`${TP}.shopKids`, 'Shop Kids'),
  },
]
