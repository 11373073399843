import { uniqBy } from 'lodash/fp'
import { connectCurrentRefinements } from 'react-instantsearch-dom'
import countFilters from 'search/utils/count-filters'
import { colors, fonts } from 'shared/lib'
import styled from 'styled-components'

import ClearRefinementsButton from './ClearRefinementsButton'
import { SearchQA } from 'shared/dataAttributes'
import { t } from 'localization'

const TP = 'search.components.filterHeader'
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
`
const FilterLabel = styled.div`
  text-transform: uppercase;
  ${fonts.SUBTITLE_2}
`

const RefinementCount = styled.span`
  color: ${colors.FC2_GREY};
  margin-left: 5px;
`

const FilterHeader = ({ items }) => {
  const dedupedItems = uniqBy('id')(items)
  const filterCount = countFilters(dedupedItems)
  return (
    <Wrapper>
      <FilterLabel data-qa={SearchQA.DesktopGridAddFilterLabel}>
        {t(`${TP}.filter`, 'Filter')}{' '}
        {filterCount > 0 && <RefinementCount>{filterCount}</RefinementCount>}
      </FilterLabel>
      <ClearRefinementsButton />
    </Wrapper>
  )
}

export default connectCurrentRefinements(FilterHeader)
