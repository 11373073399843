import React, { FC } from 'react'
import { colors, fonts, media, styles } from 'shared/lib'
import styled from 'styled-components'
import createSanitizedHtmlObject from 'shared/lib/sanitizeHtml'
import { PagesQA } from 'shared/dataAttributes'

const HeaderWrapper = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 60px ${styles.mobilePageLayoutPadding};
  background-color: ${colors.FC2_WHITE};
  ${media.large`
    margin-bottom: 40px;
    align-items: center;
    background-color: ${colors.FC2_WHITE};
    padding: 80px 150px;
  `};
`
const Meta = styled.div<{ align: string }>`
  max-width: 800px;
  display: flex;
  flex-direction: column;
  flex: 0 1 45%;
  text-align: ${({ align }) => align || 'left'};
  ${media.large`
    order: 0;
    text-align: center;
  `}
`
const Title = styled.h1<{ as: string }>`
  ${fonts.HEADER_1}
  margin-top: 0;
`
const Description = styled.div`
  margin-top: 0;
  ${fonts.BODY_TEXT}
  a {
    color: inherit;
    text-decoration: underline;
    font: inherit;
    font-weight: 500;
    &:hover {
      filter: brightness(60%);
    }
  }
`

interface IGridHeaderProps {
  title: string
  description?: string
  textAlign?: string
  as?: string
}

const GridHeader: FC<IGridHeaderProps> = ({ title, description, textAlign, as = 'h1' }) => {
  return (
    <HeaderWrapper>
      <Meta align={textAlign}>
        <Title data-qa={PagesQA.GridTitle} data-testid="gridTitle" as={as}>
          {title}
        </Title>
        {description && (
          <Description
            data-qa={PagesQA.GridDescription}
            data-testid="gridDescription"
            dangerouslySetInnerHTML={createSanitizedHtmlObject(description)}
          />
        )}
      </Meta>
    </HeaderWrapper>
  )
}

export default GridHeader
