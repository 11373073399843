import { FC, Fragment, useState } from 'react'
import { MobileSearchFiltersLayout, SearchPagination, SearchSort } from 'search'
import { renderUntil } from 'shared/hocs/render'
import { media, styles } from 'shared/lib'
import styled from 'styled-components'

import MobileFilterButton from './MobileFilterButton'
import ResultsCounter from './ResultsCounter'
import SearchGrid from './SearchGrid'

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${styles.mobilePageLayoutPadding};
  ${media.large`display: none;`}
`

const Layout = styled.div`
  padding: ${styles.mobilePageLayoutPadding};
`
interface MobileAlgoliaSearchGridLayoutProps {
  defaultRefinement: string
  sortConfig
}

const MobileAlgoliaSearchGridLayout: FC<MobileAlgoliaSearchGridLayoutProps> = ({
  defaultRefinement,
  sortConfig,
}) => {
  const [showFilterTakeOver, setShowFilterTakeOver] = useState(false)
  const handleFilterButtonClick = () => {
    setShowFilterTakeOver(!showFilterTakeOver)
  }
  return (
    <Fragment>
      <ActionsWrapper>
        <MobileFilterButton onClick={handleFilterButtonClick} />
        <SearchSort items={sortConfig} defaultRefinement={defaultRefinement} />
      </ActionsWrapper>
      <Layout>
        <ResultsCounter />
        <MobileSearchFiltersLayout show={showFilterTakeOver} toggle={handleFilterButtonClick} />
        <SearchGrid />
        <SearchPagination />
      </Layout>
    </Fragment>
  )
}

MobileAlgoliaSearchGridLayout.displayName = 'MobileAlgoliaSearchGridLayout'

export default renderUntil.large(MobileAlgoliaSearchGridLayout)
