import { FC } from 'react'
import styled from 'styled-components'
import Image from 'shared/components/Image'
import { LinkCTA } from 'shared/components/Links'
import { colors, media, fonts } from 'shared/lib'

type ImageCardProps = {
  imageUrl: string
  href: string
  label: string
  className?: string
  qaAttr?: string
  linkAs?: string
  sizes?: string
}

export const ImageCard: FC<ImageCardProps> = ({
  imageUrl,
  href,
  linkAs,
  label,
  className,
  qaAttr,
  sizes,
}) => {
  return (
    <Card className={className}>
      <LinkCTA qaAttr={`${qaAttr}Link`} href={href} linkAs={linkAs} color="inherit">
        <Image
          alt={label}
          data-qa={`${qaAttr}Image`}
          data-testid="image"
          sizes={sizes}
          src={imageUrl}
          width={380}
          height={270}
        />
        <MobileLabel>{label}</MobileLabel>
      </LinkCTA>
      <DesktopLabelLink href={href} linkAs={linkAs}>
        {label}
      </DesktopLabelLink>
    </Card>
  )
}
export default ImageCard

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.FC2_WHITE};
  width: 100%;
  margin-bottom: 10px;
  ${media.medium`
    margin: 10px;
  `}
`

const DesktopLabelLink = styled(LinkCTA)`
  display: none;
  ${media.medium`
    display: block;
    margin-top: 15px;
  `}
`
const MobileLabel = styled.h3`
  ${fonts.LINK}
  font-weight: inherit;
  margin: 30px 20px;
  ${media.medium`
    display: none;
  `}
`
