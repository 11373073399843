import { produce } from 'immer'
import { isEmpty, omit, omitBy } from 'lodash/fp'
import { SearchState } from 'react-instantsearch-core'
import { findResultsState } from 'react-instantsearch-dom/server'
import { AlgoliaIndex } from 'search'
import AlgoliaSearchClient from 'search/components/AlgoliaSearchClient'
import PageLevelInstantSearch from 'search/components/PageLevelInstantSearch'

interface ISeoMapping {
  algoliaType: AlgoliaType
  algoliaName: string
  urlName: string
}

enum AlgoliaType {
  RefinementList,
  Range,
}

const createSeoMapping = (
  algoliaType: AlgoliaType,
  algoliaName: string,
  urlName: string,
): ISeoMapping => ({ algoliaType, algoliaName, urlName })

const SeoMappingList: ISeoMapping[] = [
  createSeoMapping(AlgoliaType.RefinementList, 'brand_name', 'brand'),
  createSeoMapping(AlgoliaType.RefinementList, 'silhouette', 'model'),
  createSeoMapping(AlgoliaType.RefinementList, 'presentation_size', 'size'),
  createSeoMapping(AlgoliaType.RefinementList, 'size_us_men', 'size_men'),
  createSeoMapping(AlgoliaType.RefinementList, 'size_us_women', 'size_women'),
  createSeoMapping(AlgoliaType.RefinementList, 'size_us_youth', 'size_youth'),
  createSeoMapping(AlgoliaType.RefinementList, 'shoe_condition', 'condition'),
  createSeoMapping(AlgoliaType.RefinementList, 'color', 'color'),
  createSeoMapping(AlgoliaType.RefinementList, 'single_gender', 'gender'),
  createSeoMapping(AlgoliaType.RefinementList, 'category', 'category'),
  createSeoMapping(AlgoliaType.RefinementList, 'product_category', 'product_category'),
  createSeoMapping(AlgoliaType.RefinementList, 'product_type', 'product_type'),
  createSeoMapping(AlgoliaType.RefinementList, 'designer', 'designer'),
  createSeoMapping(AlgoliaType.RefinementList, 'collection_slugs', 'collection'),
  createSeoMapping(AlgoliaType.Range, 'lowest_price_cents', 'price_cents'),
  createSeoMapping(AlgoliaType.Range, 'lowest_price_cents_usd', 'price_cents_usd'),
  createSeoMapping(AlgoliaType.Range, 'release_year', 'year'),
]

const omitByEmptyValues = omitBy(isEmpty)

const omitRefinementList = omitBy((refinement) => {
  const isRefinementEmpty = isEmpty(refinement)
  const isEmptyArray = Array.isArray(refinement) && isEmpty(omitByEmptyValues(refinement))

  return isRefinementEmpty || isEmptyArray
})

const adjustRefinementListProperties = (searchState: SearchState) => {
  if (!isEmpty(searchState.refinementList)) {
    SeoMappingList.filter((x) => x.algoliaType === AlgoliaType.RefinementList).forEach(
      (mapping) => {
        if (
          searchState.refinementList![mapping.algoliaName] &&
          !isEmpty(omitByEmptyValues(searchState.refinementList![mapping.algoliaName]))
        ) {
          searchState[mapping.urlName] = searchState.refinementList![mapping.algoliaName].join(',')
        }
      },
    )
  }
}

const adjustRangeProperties = (searchState: SearchState) => {
  if (!isEmpty(searchState.range)) {
    SeoMappingList.filter((x) => x.algoliaType === AlgoliaType.Range).forEach((mapping) => {
      if (searchState.range![mapping.algoliaName]) {
        if (searchState.range![mapping.algoliaName].min) {
          searchState[`${mapping.urlName}_min`] = searchState.range![mapping.algoliaName].min
        }
        if (searchState.range![mapping.algoliaName].max) {
          searchState[`${mapping.urlName}_max`] = searchState.range![mapping.algoliaName].max
        }
      }
    })
  }
}

export const searchStateToUrl = (searchState: SearchState) => {
  if (isEmpty(searchState)) {
    return {}
  }

  return produce(searchState, (draftUrlParams) => {
    draftUrlParams.refinementList = omitRefinementList(draftUrlParams.refinementList)

    if (draftUrlParams.page === 1) {
      delete draftUrlParams.page
    }

    if (draftUrlParams.configure) {
      draftUrlParams.configure = omit(['hitsPerPage', 'distinct', 'filters'])(
        draftUrlParams.configure,
      )
    }

    if (isEmpty(draftUrlParams.configure)) {
      delete draftUrlParams.configure
    }

    // Friendly SEO for refinement list
    adjustRefinementListProperties(draftUrlParams)

    // Friendly SEO for range
    adjustRangeProperties(draftUrlParams)

    delete draftUrlParams.refinementList
    delete draftUrlParams.range
  })
}

const defaultSearchState = {
  page: 1,
  query: undefined,
  refinementList: {},
  range: {},
}

export const urlToSearchState = (urlParams: any) => {
  if (isEmpty(urlParams)) {
    return defaultSearchState
  }

  const { query = '', page = 1 } = urlParams

  const refinementList = {}
  const range = {}

  SeoMappingList.forEach((mapping) => {
    // for refinementList object
    if (mapping.algoliaType === AlgoliaType.RefinementList && urlParams[mapping.urlName]) {
      const rawValue = urlParams[mapping.urlName]
      // `qs` does not return an array when there's a single value.
      const refinementValue = Array.isArray(rawValue)
        ? rawValue
        : rawValue.split(',').filter(Boolean)
      refinementList[mapping.algoliaName] = refinementValue.map(decodeURIComponent)
    }
    // for range object
    if (
      mapping.algoliaType === AlgoliaType.Range &&
      (urlParams[`${mapping.urlName}_min`] || urlParams[`${mapping.urlName}_max`])
    ) {
      range[mapping.algoliaName] = {
        min: urlParams[`${mapping.urlName}_min`],
        max: urlParams[`${mapping.urlName}_max`],
      }
    }
  })

  return {
    ...defaultSearchState,
    query: query ? decodeURIComponent(query) : undefined,
    page,
    refinementList,
    range,
  }
}

export const getResultsState = async (initialSearchState, searchState = {}, hitsPerPage = 8) => {
  const isSearchStateEmpty = Object.keys(searchState).length === 0
  const resultsState = await findResultsState(PageLevelInstantSearch, {
    hitsPerPage,
    indexName: AlgoliaIndex.Relevance,
    initialSearchState: isSearchStateEmpty ? {} : initialSearchState,
    searchClient: AlgoliaSearchClient,
    searchState: isSearchStateEmpty ? initialSearchState : searchState,
  })

  // If there are no relevant products in resultsState, get trending products
  const trendingResultsState = await (async () => {
    if (resultsState?.rawResults?.[0]?.nbHits === 0) {
      return await findResultsState(PageLevelInstantSearch, {
        indexName: AlgoliaIndex.Trending,
        searchClient: AlgoliaSearchClient,
        searchState: {},
      })
    }
    return null
  })()

  return { resultsState, trendingResultsState }
}
