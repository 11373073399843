import { isEmpty } from 'lodash'
import Router, { useRouter } from 'next/router'
import qs from 'qs'
import { FC, useEffect, useState } from 'react'
import { SearchResults, SearchState } from 'react-instantsearch-core'

import { AlgoliaIndex, SortOption } from 'search'
import AlgoliaResults from 'search/components/AlgoliaResults'
import AlgoliaSearchClient from 'search/components/AlgoliaSearchClient'
import AlgoliaSearchGridContext, {
  AlgoliaSearchGridContextProps,
} from 'search/components/AlgoliaSearchGridContext'
import DesktopAlgoliaSearchGridLayout from 'search/components/DesktopAlgoliaSearchGridLayout'
import MobileAlgoliaSearchGridLayout from 'search/components/MobileAlgoliaSearchGridLayout'
import PageLevelInstantSearch from 'search/components/PageLevelInstantSearch'
import { searchStateToUrl } from 'shared/utils/algolia-utils'
import GridHeader from './GridHeader'

export interface GridConfig {
  context: AlgoliaSearchGridContextProps
  regex?: RegExp
  indexPath?: string
}
export interface GridProps {
  title: string
  description?: string
  resultsState: SearchResults
  initialSearchState: SearchState
  config: GridConfig
  noResultsState: SearchResults | null
}

const replaceRouterHref = (searchState, locale) => {
  const urlParams = searchStateToUrl(searchState)
  const href = isEmpty(urlParams)
    ? `${window.location.pathname}`
    : `${window.location.pathname}?${qs.stringify(urlParams)}`

  Router.replace(href, href, { shallow: true, locale })
}

const sortConfig = () =>
  Object.keys(SortOption()).map(option => ({
    value: AlgoliaIndex[option],
    label: SortOption()[option],
  }))

const Grid: FC<GridProps> = props => {
  const { initialSearchState, resultsState, title, description, config, noResultsState } = props
  const [searchState, setSearchState] = useState(initialSearchState)
  const { context } = config
  const { locale } = useRouter()
  useEffect(() => {
    setSearchState(initialSearchState)
  }, [initialSearchState])

  const handleSearchStateChange = newSearchState => {
    replaceRouterHref(newSearchState, locale, config)
    // if searchState is empty fallback to initialSearchStatesssss
    // only an issue when toggling between mobile/desktop layouts
    setSearchState(isEmpty(newSearchState) ? initialSearchState : newSearchState)
  }

  return (
    <>
      <AlgoliaSearchGridContext.Provider value={context}>
        <PageLevelInstantSearch
          resultsState={resultsState}
          searchState={searchState}
          filters={context.filters}
          indexName={context.index || ''}
          onSearchStateChange={handleSearchStateChange}
          hitsPerPage={30}
          searchClient={AlgoliaSearchClient}
          ruleContexts={context?.ruleContexts}
        >
          <AlgoliaResults noResultsState={noResultsState}>
            <GridHeader title={title} description={description} />
            <MobileAlgoliaSearchGridLayout
              sortConfig={sortConfig()}
              defaultRefinement={context.index || AlgoliaIndex.Relevance}
            />
            <DesktopAlgoliaSearchGridLayout
              sortConfig={sortConfig()}
              defaultRefinement={context.index || AlgoliaIndex.Relevance}
            />
          </AlgoliaResults>
        </PageLevelInstantSearch>
      </AlgoliaSearchGridContext.Provider>
    </>
  )
}

export default Grid
