import DOMPurify from 'dompurify'

/**
 * Node window is used when we're server side rendering because there is no global window object
 */
let nodeWindow
if (!process.browser) {
  // tslint:disable-next-line:no-var-requires
  const { JSDOM } = require('jsdom')
  const nodeDom = new JSDOM('<!DOCTYPE html>')
  nodeWindow = nodeDom.window
}

const domPurify = DOMPurify(nodeWindow || window)

const createSanitizedHtmlObject = (storyHtml: string) => ({
  __html: domPurify.sanitize(storyHtml),
})

export default createSanitizedHtmlObject
