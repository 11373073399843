import { FC } from 'react'
import { DesktopSearchFiltersLayout, SearchPagination, SearchSort } from 'search'
import { renderStart } from 'shared/hocs/render'
import styled from 'styled-components'

import FilterHeader from './FilterHeader'
import ResultsCounter from './ResultsCounter'
import SearchGrid from './SearchGrid'
import { pageWidth } from 'shared/lib'

const RightColumn = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  margin-left: 30px;
`
const LeftColumn = styled(RightColumn)`
  margin: 0;
  flex: 1;
`
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  ${pageWidth}
`
const GridHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
interface DesktopAlgoliaSearchGridLayoutProps {
  sortConfig
  defaultRefinement: string
}

const DesktopAlgoliaSearchGridLayout: FC<DesktopAlgoliaSearchGridLayoutProps> = ({
  sortConfig,
  defaultRefinement,
}) => {
  return (
    <Wrapper>
      <LeftColumn>
        <FilterHeader />
        <DesktopSearchFiltersLayout />
      </LeftColumn>
      <RightColumn>
        <GridHeader>
          <ResultsCounter />
          <SearchSort items={sortConfig} defaultRefinement={defaultRefinement} />
        </GridHeader>
        <SearchGrid />
        <SearchPagination />
      </RightColumn>
    </Wrapper>
  )
}

export default renderStart.large(DesktopAlgoliaSearchGridLayout)
