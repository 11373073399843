import styled from 'styled-components'
import { media, colors, pageWidth } from 'shared/lib'
import ImageCard from 'homepage/ImageCard'
import { sizes } from 'shared/lib/media'

type IImage = {
  href: string
  label: string
  linkAs?: string
  qaAttr?: string
  src: string
}

interface IClickableImagesRowProps {
  imagesList: IImage[]
}

const ContentGroup = styled.div`
  ${pageWidth}
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  ${media.medium`
    flex-wrap: nowrap;
    width: 100%;
  `}
`
const Section = styled.section`
  display: flex;
  justify-content: center;
  ${media.medium`
    margin-bottom: 10px;
    padding: 40px 30px;
    background-color: ${colors.FC2_WHITE};
  `}
  ${media.large`
    padding: 80px 30px;
  `}
`

export const ClickableImagesRow = ({ imagesList }: IClickableImagesRowProps) => {
  return (
    <Section>
      <ContentGroup>
        {imagesList.map(image => (
          <ImageCard
            qaAttr={image.qaAttr || ''}
            href={image.href}
            imageUrl={image.src}
            label={image.label}
            linkAs={image.linkAs}
            key={image.src}
            sizes={`(min-width: ${sizes.medium}px) 500px, 100vw`}
          />
        ))}
      </ContentGroup>
    </Section>
  )
}

export default ClickableImagesRow
