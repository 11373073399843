import { filter, includes, map, pipe, sum, uniqBy } from 'lodash/fp'
import { Hit, Refinement } from 'react-instantsearch-core'
import { connectCurrentRefinements, connectHits } from 'react-instantsearch-dom'
import { SearchFiltersRefinement } from 'search/enums/search'
import mapHitsToProducts, { SearchHit } from 'search/utils/mapHitsToProducts'
import ProductGrid from 'shared/components/ProductGrid/ProductGrid'
import useCurrency from 'shared/hooks/useCurrency'
import { useGridContext } from './AlgoliaSearchGridContext'

interface NewSearchGridProps {
  items: Refinement[]
  hits: Hit<SearchHit>[]
}

function withDedupeRefinements<T extends object>(WrappedComponent: React.ComponentType<T>) {
  const Component = (props: T) => (
    <WrappedComponent transformItems={(items: Refinement[]) => uniqBy('id', items)} {...props} />
  )
  return Component
}

const enhance = (BaseComponent: React.FunctionComponent<NewSearchGridProps>) =>
  connectHits(withDedupeRefinements(connectCurrentRefinements(BaseComponent)))

const sizeFilterIds = [
  SearchFiltersRefinement.MenSizes,
  SearchFiltersRefinement.WomenSizes,
  SearchFiltersRefinement.YouthSizes,
]
const filterSizeFilters = filter(({ id }) => includes(id)(sizeFilterIds))
const mapToSelectionCount = map(({ items }) => items.length)

const getSizeFilterCount = pipe(filterSizeFilters, mapToSelectionCount, sum)

export const NewSearchGrid: React.FunctionComponent<NewSearchGridProps> = ({ hits, items }) => {
  const { selectedCurrency, loading, error } = useCurrency()

  const sizeFilterCount = getSizeFilterCount(items)

  const currencyIsoCode = selectedCurrency?.isoCode || ''

  const { hasNewProductOnly } = useGridContext()

  if (loading || error) {
    return null
  }

  return (
    <ProductGrid
      products={mapHitsToProducts(currencyIsoCode)(hits)}
      maxColumns={3}
      hideAdditionalPrices={hasNewProductOnly || sizeFilterCount === 1}
    />
  )
}

export default enhance(NewSearchGrid)
